export const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};
export const bnb = {
  chainId: 97,
  name: "BNB Smart Chain Testnet",
  currency: "BNB",
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
};
export const linea = {
  chainId: 59144,
  name: "Linea",
  currency: "ETH",
  explorerUrl: "https://lineascan.build/",
  rpcUrl: "https://linea.decubate.com",
};
export const lineaSepolia = {
  chainId: 59141,
  name: "Linea Sepolia",
  currency: "ETH",
  explorerUrl: "https://sepolia.lineascan.build/",
  rpcUrl: "https://linea-sepolia.blockpi.network/v1/rpc/public",
};
export const polygon = {
  chainId: 80002,
  name: "Polygon Amoy Testnet",
  currency: "MATIC",
  explorerUrl: "https://amoy.polygonscan.com/",
  rpcUrl: "https://rpc-amoy.polygon.technology/",
};
