import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { MdLogout, MdCopyAll } from "react-icons/md";
import { bnb, polygon } from "./config/NetworkConfig";
import { useState } from "react";
const projectId = "f6b41b90ce421090b52b7ecf8ecc6ea6";
const chains = [bnb, polygon];
const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://mywebsite.com",
  icons: ["https://avatars.mywebsite.com/"],
};

const ethersConfig = defaultConfig({
  metadata,
  rpcUrl: "https://cloudflare-eth.com",
  defaultChainId: 1,
  enableEmail: true,
});

createWeb3Modal({
  ethersConfig,
  chains,
  projectId,
  enableAnalytics: true,
  themeVariables: {
    "--w3m-color-mix": "black",
    "--w3m-color-mix-strength": 70,
  },
});

export default function ConnectWallet() {
  const { address, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const [copied, setCopied] = useState(false);
  const formatAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 7)}...${address.substring(
      address.length - 5
    )}`;
  };
  const copyAddress = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    }
  };

  return (
    <div className="multiwallet-body">
      {!isConnected ? (
        <div className="styles_content__rLb_d">
          <button
            className="black-btn w-40 wow fadeInUp py-1 px-2"
            onClick={() => open()}
          >
            Connect Wallet
          </button>
        </div>
      ) : (
        <>
          <div className="d-flex buttongrp">
            <button
              className="black-btn w-40 wow fadeInUp me-2"
              onClick={copyAddress}
            >
              {formatAddress(address)}
              <MdCopyAll style={{ marginLeft: "6px" }} />
            </button>
            <button
              className="black-btn w-40 wow fadeInUp py-1 px-2"
              onClick={() => disconnect()}
            >
              <MdLogout />
            </button>
          </div>
          {copied && <span className="copied-message">Copied!</span>}
        </>
      )}
    </div>
  );
}
