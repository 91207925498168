import React from "react";
import ConnectWallet from "./ConnectWallet";

const Header = () => {
  return (
    <header className="">
      <div className="container-fluid px-100">
        <nav className="navigation" id="navigation1">
          <div className="d-flex justify-content-between align-items-center">
            <div className="nav-header">
              <a className="nav-brand" href="#">
                <img
                  alt=""
                  className="img-fluid"
                  src="assets/images/EcotradeR1.png"
                />
              </a>
              {/* <div className="nav-toggle">
                <span />
                <span />
                <span />
              </div> */}
            </div>
            <ConnectWallet />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
