import React from "react";
// import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid px-4">
        <div className="row">
          <div className="col-12 logo my-2">
            <a href="index.html">
              <img
                src="assets/images/Ecotrader.png"
                className="img-footer"
                alt="Footer Logo"
              />
            </a>
          </div>
        </div>
        <div className="row mission_social">
          <div className="col-12 col-lg-7 mb-3 mb-lg-0 mission">
            <h4>Mission</h4>
            <h6 className="my-3">
              The Renewable Energy Market is now available to small investors.{" "}
              <br />
              You can take an active part in the Green Energy Revolution
            </h6>
          </div>
          <div className="col-12 col-lg-5 social mb-3">
            <ul className="social-media list-unstyled d-flex justify-content-evenly">
              <li className="footer_li">
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61559568997199&locale=he_IL"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/facebook.svg"
                    className="social_logo"
                    alt="Facebook"
                  />
                </a>
              </li>
              <li className="footer_li">
                <a
                  target="_blank"
                  href="https://www.instagram.com/ecotrader.io/"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/instagram.svg"
                    className="social_logo"
                    alt="Instagram"
                  />
                </a>
              </li>
              <li className="footer_li">
                <a
                  target="_blank"
                  href="https://x.com/Ecotrader_io"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/twitter.svg"
                    className="social_logo"
                    alt="Twitter"
                  />
                </a>
              </li>
              <li className="footer_li">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/ecotrader/?viewAsMember=true"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/linkedin.svg"
                    className="social_logo"
                    alt="LinkedIn"
                  />
                </a>
              </li>
              <li className="footer_li">
                <a
                  target="_blank"
                  href="https://t.me/+ydxYWAGGHOBhY2Y0"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/telegram.svg"
                    className="social_logo"
                    alt="Telegram"
                  />
                </a>
              </li>
              <li className="footer_li">
                <a
                  target="_blank"
                  href="https://discord.gg/cvSFXP7H9q"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/discord.svg"
                    className="social_logo"
                    alt="Discord"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="copy-rights text-center mt-3">
          <p>Copyright © 2024 Ecotrader • All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
